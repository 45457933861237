let timer;

function openSubMenu(menuItem) {
    const sub_menu = menuItem.querySelector('.sub-menu');
    const sub_menu_toggle = menuItem.querySelector('.dropdown-toggle');

    menuItem.classList.add('is-open');

    sub_menu.setAttribute('aria-expanded', "true");
    sub_menu.classList.add('is-open');

    sub_menu_toggle.setAttribute('aria-expanded', "true");
    sub_menu_toggle.classList.add('is-open');
}

function closeSubMenu(menuItem) {
    const sub_menu = menuItem.querySelector('.sub-menu');
    const sub_menu_toggle = menuItem.querySelector('.dropdown-toggle');

    menuItem.classList.remove('is-open');

    sub_menu.setAttribute('aria-expanded', "false");
    sub_menu.classList.remove('is-open');

    sub_menu_toggle.setAttribute('aria-expanded', "false");
    sub_menu_toggle.classList.remove('is-open');
}

function toggleSubMenu(e) {
    e.stopPropagation();
    const menuItem = e.currentTarget.closest('.menu-item.has-children');
    const subMenu = menuItem.querySelector('.sub-menu');
    const isOpen = document.querySelectorAll('#site-menu .is-open');

    if(menuItem.classList.contains('is-open')){
        menuItem.classList.remove('is-open');
    }else{
        for (let i = 0; i < is-open.length; i++) {
            isOpen[i].classList.remove('is-open');
        }
        menuItem.classList.add('is-open');
    }

    if(subMenu.classList.contains('is-open')){
        e.target.classList.remove('is-open');
        e.target.setAttribute('aria-expanded', "false");
        subMenu.classList.remove('is-open');
    }else{
        for (let i = 0; i < is-open.length; i++) {
            isOpen[i].classList.remove('is-open');
        }
        e.target.classList.add('is-open');
        e.target.setAttribute('aria-expanded', "true");
        subMenu.classList.add('is-open');
    }
}

function mouseoverToggle(e) {
    
    const menuItem = this;
    const isOpen = document.querySelectorAll('#site-menu .is-open');
    for (let i = 0; i < isOpen.length; i++) {
        is-open[i].classList.remove('is-open');
    }
    openSubMenu(menuItem);
    clearTimeout(timer); //reset the timeout used below on mouseout
    
}

function mouseoutToggle(e) {
    const menuItem = this;
    timer = setTimeout(function(){ //Set a timeout to accomodate any mouse inaccuracies from users
        closeSubMenu(menuItem);
        const isOpen = document.querySelectorAll('#site-menu .is-open');
        for (let i = 0; i < isOpen.length; i++) {
          is-open[i].classList.remove('is-open');
        }
    }, 650);
}


const menuItemsWithChildren = document.querySelectorAll('.menu-item.has-children');
for (let i = 0; i < menuItemsWithChildren.length; i++) {
    const dropdownToggle = menuItemsWithChildren[i].querySelector('.dropdown-toggle');
    dropdownToggle.addEventListener("click", toggleSubMenu, false);

    if (window.innerWidth > 1280){
        menuItemsWithChildren[i].addEventListener("mouseover", mouseoverToggle, false);
        menuItemsWithChildren[i].addEventListener("mouseout", mouseoutToggle, false);
    }
    window.addEventListener("resize", function() {
        if (window.innerWidth > 1280){
            menuItemsWithChildren[i].addEventListener("mouseover", mouseoverToggle, false);
            menuItemsWithChildren[i].addEventListener("mouseout", mouseoutToggle, false);
        } else if (window.innerWidth < 1280){
            menuItemsWithChildren[i].removeEventListener("mouseover", mouseoverToggle, false);
            menuItemsWithChildren[i].removeEventListener("mouseout", mouseoutToggle, false);
        }
    });
}

//Listen for clicks outside of the is-open submenu, and close the menu if found
document.addEventListener('click', function handleClickOutsideBox(event) {
    const openMenuItem = document.querySelector('.menu-item.is-open');
    if (typeof(openMenuItem) != 'undefined' && openMenuItem != null) {
        if (!openMenuItem.contains(event.target)) {
            openMenuItem.querySelector('.dropdown-toggle.is-open').setAttribute('aria-expanded', "false");
            openMenuItem.querySelector('.dropdown-toggle.is-open').classList.remove('is-open');
            openMenuItem.classList.remove('is-open');
            openMenuItem.querySelector('.sub-menu').classList.remove('is-open');
        }
    }
});


const mobileMenuToggle = document.querySelector('#mobile-menu-toggle');

if (typeof(mobileMenuToggle) != 'undefined' && mobileMenuToggle != null) {
    mobileMenuToggle.addEventListener("click" , openMobileMenu , false);
}

const menuBackdrop = document.querySelector('#menu-backdrop-filter');
if (typeof(menuBackdrop) != 'undefined' && menuBackdrop != null) {
    menuBackdrop.addEventListener("click" , closeMainMenu , false);
}


function openMobileMenu(event) {
    let docBody = document.body;
    let toggle = event.currentTarget;
    let siteMenu = document.querySelector('#site-nav');
    docBody.classList.add('overflow-hidden');
    siteMenu.classList.add('is-open');
    toggle.removeEventListener("click" , openMobileMenu , false);
    toggle.addEventListener("click" , closeMobileMenu , false);
}

function closeMobileMenu(event) {
    let docBody = document.body;
    let toggle = event.currentTarget;
    let siteMenu = document.querySelector('#site-nav');
    docBody.classList.remove('overflow-hidden');
    siteMenu.classList.remove('is-open');
    toggle.removeEventListener("click" , closeMobileMenu , false);
    toggle.addEventListener("click" , openMobileMenu , false);
}

function closeMainMenu() {
    let docBody = document.body;
    let siteMenu = document.querySelector('#site-nav');
    let toggle = document.querySelector('#mobile-menu-toggle');
    docBody.classList.remove('overflow-hidden');
    siteMenu.classList.remove('is-open');
    toggle.removeEventListener("click" , closeMobileMenu , false);
    toggle.addEventListener("click" , openMobileMenu , false);
}

window.addEventListener("resize", function() {
    if (window.innerWidth > 1280){
        closeMainMenu()
    }
});