// Function that builds Case Study Showcase Sliders
const buildCsShowcaseSlider = sliderElm => {
    const sliderIdentifier = sliderElm.dataset.id;
    return new Swiper(`.casestudy-showcase--slider[data-id="${sliderIdentifier}"]`, {
        slidesPerView: 1,
        spaceBetween: 0,
        centeredSlides: false,
        a11y: true,
        loop: false,
        grabCursor: true,
        watchSlidesProgress: true,

        preloadImages: false,
        lazy: true,

        navigation: {
            nextEl: `.button-next-${sliderIdentifier}`,
            prevEl: `.button-prev-${sliderIdentifier}`,
        },

        keyboard: {
            enabled: true,
            onlyInViewport: false,
        }
    });
}

// Get all of the news sliders on the page
const allCsShowcaseSliders = document.querySelectorAll('.casestudy-showcase--slider');

// Loop over all of the fetched sliders and apply Swiper on each one.
allCsShowcaseSliders.forEach(slider => buildCsShowcaseSlider(slider));



// Function that builds autoplay image Sliders
const buildImgSlider = sliderElm => {
    const sliderIdentifier = sliderElm.dataset.id;
    return new Swiper(`.img--slider[data-id="${sliderIdentifier}"]`, {
        slidesPerView: 'auto',
        spaceBetween: 20,
        centeredSlides: false,
        a11y: true,
        loop: true,
        freeMode: true,
        grabCursor: true,
        speed: 12500,
        freeModeMomentum: false,
        watchSlidesProgress: true,
        preloadImages: false,
        lazy: true,

        autoplay: {
            delay: 1,
            disableOnInteraction: false
        },

        keyboard: {
            enabled: true,
            onlyInViewport: false,
        }
    });
}

// Get all of the news sliders on the page
const allImgSliders = document.querySelectorAll('.img--slider');

// Loop over all of the fetched sliders and apply Swiper on each one.
allImgSliders.forEach(slider => buildImgSlider(slider));

// Function that builds award Sliders
const buildAwardSlider = sliderElm => {

    const awardSlides = sliderElm.querySelectorAll('.swiper-slide');

    var awardSlidesLength = awardSlides.length;

    const sliderIdentifier = sliderElm.dataset.id;

    let awardSwiper;

    if(awardSlidesLength >= 3){
        sliderElm.classList.add('content-width');
        awardSwiper = new Swiper(`.awards--slider[data-id="${sliderIdentifier}"]`, {
            slidesPerView: 'auto',
            spaceBetween: 0,
            centeredSlides: false,
            a11y: true,
            loop: false,
            freeMode: true,
            grabCursor: true,
            freeModeMomentum: false,
            watchOverflow: true,
            watchSlidesProgress: true,
            preloadImages: false,
            lazy: true,

            keyboard: {
                enabled: true,
                onlyInViewport: false,
            }
        });
    }else{
        sliderElm.classList.add('content-width');
        awardSwiper = new Swiper(`.awards--slider[data-id="${sliderIdentifier}"]`, {
            slidesPerView: 'auto',
            spaceBetween: 0,
            centeredSlides: false,
            a11y: true,
            loop: false,
            freeMode: true,
            grabCursor: true,
            freeModeMomentum: false,
            watchOverflow: true,
            watchSlidesProgress: true,
            preloadImages: false,
            lazy: true,

            keyboard: {
                enabled: true,
                onlyInViewport: false,
            }
        });
    }

    let awardDescItems = document.querySelectorAll('.award--content-container .award--content-item');

    awardSwiper.on('click', function (e) {

        let slideIndex;
        slideIndex = awardSwiper.clickedIndex;

        let awardDesc = document.querySelector('.award--content-item[data-index="' + slideIndex + '"]');
        let awardDescItems = document.querySelectorAll('.award--content-container .award--content-item');

        for (let i = 0; i < awardSwiper.slides.length; i++) {
            awardSwiper.slides[i].classList.remove('is-open')
        }

        for (let i = 0; i < awardDescItems.length; i++) {
            awardDescItems[i].classList.remove('is-open');
        }

        awardSwiper.clickedSlide.classList.add('is-open');

        awardDesc.classList.add('is-open');
    });

    for (let i = 0; i < awardDescItems.length; i++) {
        let index = i;
        awardDescItems[i].setAttribute("data-index", index);
    }

    return awardSwiper;
}

// Get all of the news sliders on the page
const allAwardSliders = document.querySelectorAll('.awards--slider');

// Loop over all of the fetched sliders and apply Swiper on each one.
allAwardSliders.forEach(slider => buildAwardSlider(slider));




// Function that builds Service Section Sliders
const buildServiceSlider = sliderElm => {
    const sliderIdentifier = sliderElm.dataset.id;
    return new Swiper(`.services--slider[data-id="${sliderIdentifier}"]`, {
        slidesPerView: 1.1,
        spaceBetween: 15,
        centeredSlides: false,
        a11y: true,
        loop: false,
        freeMode: true,
        grabCursor: true,
        freeModeMomentum: false,
        watchSlidesProgress: true,
        preloadImages: false,
        lazy: true,

        navigation: {
            nextEl: `.button-next-${sliderIdentifier}`,
            prevEl: `.button-prev-${sliderIdentifier}`,
        },

        keyboard: {
            enabled: true,
            onlyInViewport: false,
        },

        breakpoints: {
            500: {
                slidesPerView: 1.5,
                spaceBetween: 30,
            },
            768: {
                slidesPerView: 2.1,
                spaceBetween: 30,
            },
            1324: {
                slidesPerView: 2,
                spaceBetween: 30,
            }
        },
    });
}

// Get all of the Service sliders on the page
const allServiceSliders = document.querySelectorAll('.services--slider');

// Loop over all of the fetched sliders and apply Swiper on each one.
allServiceSliders.forEach(slider => buildServiceSlider(slider));


// Function that builds Service Section Sliders
const buildSubServiceSlider = sliderElm => {
    const sliderIdentifier = sliderElm.dataset.id;
    return new Swiper(`.services--sub-slider[data-id="${sliderIdentifier}"]`, {
        slidesPerView: 1,
        spaceBetween: 15,
        centeredSlides: false,
        a11y: true,
        loop: false,
        freeMode: false,
        grabCursor: true,
        freeModeMomentum: false,
        watchSlidesProgress: true,
        preloadImages: false,
        lazy: true,

        navigation: {
            nextEl: `.button-next-${sliderIdentifier}`,
            prevEl: `.button-prev-${sliderIdentifier}`,
        },

        keyboard: {
            enabled: true,
            onlyInViewport: false,
        },

        breakpoints: {
            600: {
                slidesPerView: 2,
                spaceBetween: 30,
            },
            1024: {
                slidesPerView: 3,
                spaceBetween: 60,
            }
        },
    });
}

// Get all of the news sliders on the page
const allSubServiceSliders = document.querySelectorAll('.services--sub-slider');

// Loop over all of the fetched sliders and apply Swiper on each one.
allSubServiceSliders.forEach(slider => buildSubServiceSlider(slider));



// Function that builds Service Section Sliders
const buildFilterSlider = sliderElm => {
    const sliderIdentifier = sliderElm.dataset.id;
    return new Swiper(`.filter-slider[data-id="${sliderIdentifier}"]`, {
        slidesPerView: 'auto',
        spaceBetween: 10,
        centeredSlides: false,
        a11y: true,
        loop: false,
        freeMode: true,
        grabCursor: true,
        freeModeMomentum: false,
        watchSlidesProgress: true,
        preloadImages: false,
        lazy: true,

        navigation: {
            nextEl: `.button-next-${sliderIdentifier}`,
            prevEl: `.button-prev-${sliderIdentifier}`,
        },

        keyboard: {
            enabled: true,
            onlyInViewport: false,
        },
    });
}

// Get all of the news sliders on the page
const allFilterSliders = document.querySelectorAll('.filter-slider');

// Loop over all of the fetched sliders and apply Swiper on each one.
allFilterSliders.forEach(slider => buildFilterSlider(slider));




// Function that builds Case Study Showcase Sliders
const buildlogoSlider = sliderElm => {
    const sliderIdentifier = sliderElm.dataset.id;
    return new Swiper(`.logo--slider[data-id="${sliderIdentifier}"]`, {
        slidesPerView: 1,
        spaceBetween: 0,
        centeredSlides: false,
        a11y: true,
        loop: false,
        grabCursor: true,
        watchSlidesProgress: true,

        preloadImages: false,
        lazy: true,

        navigation: {
            nextEl: `.button-next-${sliderIdentifier}`,
            prevEl: `.button-prev-${sliderIdentifier}`,
        },

        keyboard: {
            enabled: true,
            onlyInViewport: false,
        },

        breakpoints: {
            500: {
                slidesPerView: 2,
            },
            800: {
                slidesPerView: 3,
            },
            1166: {
                slidesPerView: 4,
            }
        },
    });
}

// Get all of the news sliders on the page
const allLogoSliders = document.querySelectorAll('.logo--slider');

// Loop over all of the fetched sliders and apply Swiper on each one.
allLogoSliders.forEach(slider => buildlogoSlider(slider));