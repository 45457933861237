Object.defineProperty(HTMLMediaElement.prototype, 'playing', {
    get: function(){
        return !!(this.currentTime > 0 && !this.paused && !this.ended && this.readyState > 2);
    }
})

function createObserver(element2Observe) {
    let observer;
  
    let options = {
      root: null,
      rootMargin: "0px",
      threshold: 0.25
    };
  
    observer = new IntersectionObserver(handleFooterIntersect, options);
    observer.observe(element2Observe);
}

function handleFooterIntersect(entries, observer) {
    let keyCTAs = document.querySelector('.key-cta-links')
    entries.forEach((entry) => {
        console.log(entry);
        if (entry.intersectionRatio == 1){
            keyCTAs.classList.remove('hidden');
        }else{
            keyCTAs.classList.add('hidden');
        }
    });
}

let siteFooter = document.querySelector('footer#colophon');
let siteMain = document.querySelector('main#content');
let siteFooterHeight = siteFooter.offsetHeight;
let windowHeight = window.innerHeight;
siteMain.style.marginBottom = siteFooterHeight + 'px';
if (siteFooterHeight > windowHeight) {
    siteFooter.style.position = 'relative';
    siteMain.style.marginBottom = '0px';
    createObserver(siteFooter);
}

window.addEventListener('resize', function() {
    siteFooterHeight = siteFooter.offsetHeight;
    windowHeight = window.innerHeight;
    if (siteFooterHeight > windowHeight) {
        siteFooter.style.position = 'relative';
        siteMain.style.marginBottom = '0px';
        return;
    }
    siteFooter.style.position = 'fixed';
    siteMain.style.marginBottom = siteFooterHeight + 'px';
});

let siteHeader = document.querySelector('header#masthead');
let contentHeaderHomepage = document.querySelector('#content-header--homepage');
let contentHeaderTopLevel = document.querySelector('#content-header--yop_level');
let contentHeaderMinorLevel = document.querySelector('#content-header--minor_level');
let siteHeaderHeight = siteHeader.offsetHeight;
if (typeof(contentHeaderHomepage) != 'undefined' && contentHeaderHomepage != null) {
    contentHeaderHomepage.style.paddingTop = siteHeaderHeight + 'px';
}
if (typeof(contentHeaderTopLevel) != 'undefined' && contentHeaderTopLevel != null) {
    contentHeaderTopLevel.style.paddingTop = siteHeaderHeight + 'px';
}
if (typeof(contentHeaderMinorLevel) != 'undefined' && contentHeaderMinorLevel != null) {
    contentHeaderMinorLevel.style.paddingTop = siteHeaderHeight + 'px';
}

var tag = document.createElement('script');

tag.src = "https://www.youtube.com/iframe_api";
var firstScriptTag = document.getElementsByTagName('script')[0];
firstScriptTag.parentNode.insertBefore(tag, firstScriptTag);


MicroModal.init({
    onShow: function(){ document.body.classList.add('modal-open'); },
    onClose: function(){ document.body.classList.remove('modal-open'); },
    disableScroll: true,
    awaitOpenAnimation: true,
    awaitCloseAnimation: true,
    debugMode: true
  });